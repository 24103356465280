import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { DefaultGtag } from '../../utils/misc'
import '../../styles/Article.css'


const mainStyles = {
  display: "flex",
  flexDirection: "column"
}

const ArticlePage = () => {
  useEffect(() => {
    setTimeout(function () {
      // need delay, otherwise not working on firefox...
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, 100);
  }, [])

  return (
    <Layout>
      <main style={mainStyles}>
        <div className="blogpage">
          <div className="blog">
            <h1 className="title">What to Include on Your Restaurant Website</h1>
            <p>What elements does your restaurant website need to stand out? It’s not always about the impressive graphics and animations. In fact, less is more in many cases. There are some sites out there that put all their effort into drawing the customer’s attention but forget to list even the most basic aspects.</p>
            <p>Below are the most important items to include on your site to give your restaurant an impressive digital footprint.</p>

            <h2>1. Start with the Basics <span style={{ fontStyle: "normal" }}>📍</span></h2>
            <p>Important information such as your establishment’s phone number, address (preferably with Google Maps location), and operating hours should be easy to locate. Visitors intuitively look on the top or very bottom of the page for contact info, so it’s a good idea to place your information in these spots.</p>
            <p>In this modern digital age, people demand information quickly. No one wants to click around looking for something as simple as your phone number. Make sure all the information is kept up to date and you mention any changes to your operating hours during holidays at the top of your homepage. </p>

            <h2>2. Intuitive Layout <span style={{ fontStyle: "normal" }}>💻</span></h2>
            <p>The design and layout of your website also need to be very intuitive and easy to use. Choose a design that fits your restaurant concept and don’t be afraid to hire a professional if need be, but this could cost you more than you’re willing to spend.</p>
            <p>With so many platforms out there that help you create digital solutions for your restaurant’s needs such as <a href="https://takeaseat.io" rel="author">Take a Seat</a>, you won’t need to hire anyone. Take a Seat has customizable templates that allow users to build their digital presence in less than 10 minutes!</p>
            <p>Many parts of your website are customizable with this platform including branding and many themes to choose from. More importantly, Take a Seat also optimizes your website for mobile viewing, which brings us to the next section.</p>

            <h2>3. Mobile-Friendly Interface <span style={{ fontStyle: "normal" }}>📱</span></h2>
            <p>Mobile browsing makes up <a href="https://www.statista.com/statistics/277125/share-of-website-traffic-coming-from-mobile-devices/" rel="opener">over 50%</a> of Internet surfing, so you are cutting out a significant portion of your audience without a mobile-friendly site. Take a Seat makes sure that your website is optimized for mobile viewing so customers can easily find the relevant information on their smartphones.</p>

            <h2>4. High-Quality Photos <span style={{ fontStyle: "normal" }}>📷</span></h2>
            <p>Include the best photos that showcase what your restaurant is all about. The pictures you choose paint an impression of your business. Pick ones that showcase your entire business, products and staff members. After all, a picture is worth a thousand words.</p>
            <p>Each carefully selected picture should encompass the ambiance of your restaurant, the atmosphere and the overall vibe customers will get when they step in. They should have an idea of what type of establishment you’re running to understand if they should dress casually or formally.</p>
            <p>The photos are also the most efficient way to get diners through the door. Make sure you don’t photoshop the pics too much, because you wouldn’t want your guests to be disappointed with the actual product.</p>

            <h2>5. Social Media Links <span style={{ fontStyle: "normal" }}>🔗</span></h2>
            <p>Guests instinctively think a business with functioning social media accounts that see plenty of traffic is more legitimate. Twitter, Facebook, Instagram and even YouTube and TikTok are excellent social media platforms that can showcase your product.</p>
            <p>Like with your business contact information, you should always make sure your social media accounts are kept current. Social media is where guests go to check for additional info such as reviews, more photos of what to expect, and to gain a sense of customer satisfaction.</p>
            <p>Social media is also a great avenue for marketing. When people are looking for good eats at a certain location, many take to the geo-location feature on Instagram to discover what’s around. Make good use of hashtags, reels and story highlights for the perfect marketing tool.</p>

            <h2>6. Have the Menus Front and Center <span style={{ fontStyle: "normal" }}>🍱</span></h2>
            <p>While the vibe of your restaurant makes up a good portion of why customers will return, it’s mostly about your food. <a href="https://restaurant.opentable.com/resources/holiday-marketing/" rel="opener">Over 90%</a> of diners will look at the menu before deciding where to eat, so it’s a good idea to include an enticing display of your menu on the site.</p>
            <p>Once again, Take a Seat comes to the rescue to help you create a digital menu to improve user experience with QR codes, search capabilities and keyword filtering.</p>

            <h2>7. Easy Online Booking and Ordering System <span style={{ fontStyle: "normal" }}>🥡</span></h2>
            <p>An easy online reservation system can help boost your business. Creating an online ordering system will ensure you don’t lose out on the take-out business. Every once in a while, guests feel like taking their favorite dishes home to enjoy a quiet night in rather than mingle with other guests. Giving them a channel to do so easily where they input what they want for pickup will increase a diner’s motivation to choose your restaurant over another.</p>

            <h2>8. Include an About Page <span style={{ fontStyle: "normal" }}>📜</span></h2>
            <p>The “About Page” on your site is where you can share your restaurant’s story and the journey you took to get where you are today. This section will allow readers and guests to understand the concept of your restaurant, what you represent, your values and it’s also where you can highlight community outreach support and volunteer efforts.</p>
            <p>Remember that a dining establishment is more than just the food. The staff, friendly atmosphere, service, and interior decor also play big roles in defining your business. An About Page that also includes introductions to the team members and maybe even personal quotes from each will also help create a more personal touch.</p>

            <h2>9. Highlight Customer Reviews <span style={{ fontStyle: "normal" }}>✅</span></h2>
            <p>Don’t be afraid to brag about stellar reviews. Highlight them, pin them, link them, do whatever you need to let loyal and new customers know how popular your food is. Most diners will take reviews into account before choosing where to eat. This is also why linking to social media where potential customers can view plenty of positive reviews and comments will also help boost your business.</p>

            <h2>10. Merchandise and Gift Cards <span style={{ fontStyle: "normal" }}>🎁</span></h2>
            <p>If your restaurant incorporates loyalty programs, then you can also consider implementing gift cards and even merchandise if your restaurant has a cute logo or mascot. Make them available for purchase on your site as an alternative way to drive revenue.</p>
            <p>Your merch, gift cards, and loyalty point system can also be used for marketing. Holidays could offer free mugs with a limited edition logo that suits the occasion with a total bill over a certain amount. You could even expand your line to clothing and specialized treats only your restaurant can offer.</p>

            <br />
            <p>We hope that you enjoyed this article. If you have any questions, our team is here for you at <a rel="nofollow" href="mailto:team@takeaseat.io">team@takeaseat.io</a> and on social platforms: <a href="https://www.facebook.com/takeaseat.io" rel="noreferrer">Facebook</a>, <a href="https://twitter.com/Takeaseat_io" rel="noreferrer">Twitter</a> and <a href="https://www.linkedin.com/company/takeaseat-io" rel="noreferrer">LinkedIn</a> .</p>
            <p style={{ paddingBottom: "3rem" }}>Your <a href='https://takeaseat.io' rel="noreferrer">Take a Seat</a> team</p>
          </div>
        </div>
      </main>
    </Layout >
  )
}

export default ArticlePage

export const Head = () => <>
  <DefaultGtag />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
  <meta property="og:site_name" content="Blog of Take a Seat" />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
  <meta name="twitter:site" content="@Takeaseat_io" />
  <meta name="twitter:creator" content="@Takeaseat_io" />
  <link rel="canonical" href="https://takeaseat.io/article/what-to-include-on-your-restaurant-website/" />
  {/* <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/article/what-to-include-on-your-restaurant-website/" />
  <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/article/what-to-include-on-your-restaurant-website/" />
  <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/article/what-to-include-on-your-restaurant-website/" />
  <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/article/what-to-include-on-your-restaurant-website/" /> */}
  <title>What to Include on Your Restaurant Website</title>
  <meta name="description" content="What elements does your restaurant website need to stand out? It’s not always about the impressive graphics and animations. In fact, less is more in many cases." />
  <meta property="og:title" content="What to Include on Your Restaurant Website" />
  <meta property="og:description" content="What elements does your restaurant website need to stand out? It’s not always about the impressive graphics and animations. In fact, less is more in many cases." />
  <meta property="og:url" content="https://takeaseat.io/article/what-to-include-on-your-restaurant-website/" />
  <meta name="twitter:title" content="What to Include on Your Restaurant Website" />
  <meta name="twitter:description" content="What elements does your restaurant website need to stand out? It’s not always about the impressive graphics and animations. In fact, less is more in many cases." />
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "headline": "What to Include on Your Restaurant Website",
      "author": "Take a Seat",
      "description": "What elements does your restaurant website need to stand out? It’s not always about the impressive graphics and animations. In fact, less is more in many cases.",
      "url": "https://takeaseat.io/article/what-to-include-on-your-restaurant-website/",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://takeaseat.io/article/what-to-include-on-your-restaurant-website/"
      },
      "datePublished": "2022-05-30",
      "dateModified": "2022-09-30",
      "publisher": {
        "@type": "Organization",
        "name": "Take a Seat",
        "logo": {
          "@type": "ImageObject",
          "url": "https://takeaseat.io/media/icons/tas-230427.svg"
        }
      },
      "image": {
        "@type": "ImageObject",
        "url": "https://takeaseat.io/static/media/images/keyboard-gab90d7ee9_640.jpg"
      }
    })}
  </script>
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","index", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;